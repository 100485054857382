<template>
  <!-- 横屏 -->
  <horizontalScreen class="v-game-course-5-8"> 
    <!-- <scaleWrap> -->
      <div class="home">
          <div class="word-container">
            <div :id="`word${index}`" class="word" v-for="(word, index) in showWordList.slice(0, 3)" :key="word.name">
              <img class="flower" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game5-8/anime/type0/0.png"/>
              <div v-show="word.showIcon" class="icon" @click.stop.prevent="word.clicked ? null : wordClickHandler($event, word, index)">
                <div class="icon-img" :style="{backgroundImage: 'url(' + word.icon + ')'}"> 
                </div> 
              </div>
            </div>
          </div>
          <div class="word-container">
            <div :id="`word${index+3}`" class="word" v-for="(word, index) in showWordList.slice(3, 6)" :key="word.name">
              <img class="flower" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game5-8/anime/type0/0.png"/>
              <div v-show="word.showIcon" class="icon" @click.stop.prevent="word.clicked ? null : wordClickHandler($event, word, index+3)">
                <div class="icon-img" :style="{backgroundImage: 'url(' + word.icon + ')'}"> 
                </div> 
              </div>
            </div>
          </div>
        <img class="logo" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/logo.png"/>
        <div class="sound-box-top" :style="{right: height < 700 ? '10px' : null}">
           <img @click="backHandler" class="back" src="@/assets/back.png"/>
           <img @click.stop.prevent="reverseHandler" class="reverse" src="@/assets/reverse.png"/>
           <img @click="startHandler" class="voice" src="@/assets/voice.png"/>
        </div>
        <audio id="myAudio" preload="auto"></audio>
        <audio id="failAudio" preload="auto">
          <source :src="this.failAudio">
        </audio>
        <audio id="yaoAudio" preload="auto">
          <source :src="this.yaoAudio">
        </audio>
        <audio id="lieAudio" preload="auto">
          <source :src="this.lieAudio">
        </audio>
        <audio id="outAudio" preload="auto">
          <source :src="this.outAudio">
        </audio>
        <audio id="flyAudio" preload="auto">
          <source :src="this.flyAudio">
        </audio>
        <Overlay class="mask-dialog" :show="showStartGameIcon">
          <img class="overlay-bg" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game5-8/bg.png"/>
          <div class="sound-box-top" :style="{right: height < 700 ? '10px' : null}">
            <img @click="backHandler" class="back" src="@/assets/back.png"/>
            <!-- <img @click.stop.prevent="reverseHandler" class="reverse" src="@/assets/reverse.png"/>
            <img @click="startHandler" class="voice" src="@/assets/voice.png"/> -->
          </div>
          <div class="mask-title">{{index}} {{gameName}}</div>
          <img @click="startGameHandler(true)" class="start" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/btn-start.png"/>
        </Overlay>
      </div>
    <!-- </scaleWrap> -->
  </horizontalScreen>
</template>
<script>
import anime from 'animejs/lib/anime.es.js'
import horizontalScreen from '@/components/horizontal-screen'
// import scaleWrap from '@/components/scale-wrap'
import {shuffle, isEmpty, cloneDeep} from 'lodash'
import { Overlay } from 'vant'
import mixin from '../mixin'
import {Howl} from 'howler'
import {isIOS} from '@/utils/env'

export default {
  name: 'GameCourse',
  mixins: [mixin],
  components: {
    // , scaleWrap
    horizontalScreen, Overlay
  },
  created () {
    this.yaoAudioVoice = new Howl({
      src: [this.yaoAudio],
      html5: isIOS
    });
    this.lieAudioVoice = new Howl({
      src: [this.lieAudio],
      html5: isIOS
    });
    this.outAudioVoice = new Howl({
      src: [this.outAudio],
      html5: isIOS
    });
    this.flyAudioVoice = new Howl({
      src: [this.flyAudio],
      html5: isIOS
    });
  },
  beforeDestroy () {
    this.yaoAudioVoice.pause()
    this.lieAudioVoice.pause()
    this.outAudioVoice.pause()
    this.flyAudioVoice.pause()
  },
  data() {
    return {
      yaoAudioVoice: null,
      lieAudioVoice: null,
      outAudioVoice: null,
      flyAudioVoice: null,
      index: this.$route.query.index,
      id: this.$route.query.id,
      gameName: null,
      yaoAudio: `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game5-8/sounds/yao.mp3`,
      lieAudio: `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game5-8/sounds/lie.mp3`,
      outAudio: `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game5-8/sounds/out.mp3`,
      flyAudio: `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game5-8/sounds/zhuan.mp3`,
      clickAudio: require(`@/assets/sounds/click.mp3`),
      failAudio: require(`@/assets/sounds/fail.mp3`),
      boardImgLength: 144 // 花苞
    }
  },
  methods: {
    isEmpty,
    // 面板动画
    boardGifHandler (id, index) {
      clearInterval(this.timerBoard)
      this.timerBoard = null
     
      let i = 0
      const wordTarget = document.querySelector(`#${id} div`)
      wordTarget.style.display = 'none'
      

      const target = document.querySelector(`#${id} .flower`)
      this.timerBoard = setInterval(() => {
        this.isWalk = true
        if (i == 2) {
          // document.getElementById(`yaoAudio`).play()
          this.yaoAudioVoice.play()
        }
        if (i == 40) {
          // document.getElementById(`lieAudio`).play()
          this.lieAudioVoice.play()
        }
        if (i == 58) {
          // document.getElementById(`outAudio`).play()
          this.outAudioVoice.play()
        }
        if (i == 76) {
          // document.getElementById(`flyAudio`).play()
          this.flyAudioVoice.play()
        }
        if (i>=this.boardImgLength-1) {
          clearInterval(this.timerBoard)
          this.timerBoard = null
          target.style.zIndex = 40
          // 隐藏星星
          // target.style.display = 'none'
          // word.style.visibility = 'hidden'

          // this.isWalk = false
          this.showWordList[index].clicked = false
          target.style.display = 'none'
          // 下一个字包括音效 
          this.getData(true)
        } else {
          i=i+1
          target.style.zIndex = 99
          target.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game5-8/anime/type0/${i}.png`
        }
      }, 40)
    },
    // 打乱文字排序
    async reverseHandler() {
      if (!this.isWalk){
        clearInterval(this.timerWalk)
        this.timerWalk = null
        clearInterval(this.timerSigh)
        this.timerSigh = null
        clearInterval(this.timerClap)
        this.timerClap = null
        clearInterval(this.timerDig)
        this.timerDig = null
        clearInterval(this.timerBoard)
        this.timerBoard = null

        this.audioList = []
        await this.initData()
        this.showWordList = shuffle(this.InitWordList)
        this.wordList = cloneDeep(this.showWordList)
        this.$set(this, 'showWordList', shuffle(this.InitWordList)) 
        this.$set(this, 'wordList', cloneDeep(this.showWordList)) 
        for(let i =0;i<this.InitWordList.length;i++) {
          const word = document.getElementById(`word${i}`)
          const flowerTarget = document.querySelector(`#word${i} .flower`)
          const wordTarget = document.querySelector(`#word${i} div`)
          word.style.visibility = 'visible'
          wordTarget.style.display = 'block'
          flowerTarget.style.display = 'block'
          flowerTarget.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game5-8/anime/type0/${0}.png`
        }
         for(let i =0;i<this.showWordList.length;i++) {
          this.showWordList[i].clicked = false
          const word = document.getElementById(`word${i}`)
          const flowerTarget = document.querySelector(`#word${i} .flower`)
          const wordTarget = document.querySelector(`#word${i} div`)
          word.style.visibility = 'visible'
          wordTarget.style.display = 'block'
          flowerTarget.style.display = 'block'
          flowerTarget.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game5-8/anime/type0/${0}.png`
        }
        // 一切重新开始
        this.getData()
        this.startGameHandler()
      }
    },
    // 字点击
    wordClickHandler(event, word, index) {
      console.log('word', word, event.target.className)
      console.log('isWalk', this.isWalk)

      if (this.isWalk) return

      let parentNode = event.target.parentElement
      if (event.target.className == 'icon-img') {
        parentNode = event.target.parentElement.parentElement
      } else {
        parentNode = event.target.parentElement
      }
      anime({
        targets: parentNode,
        scale: [
          { value: 1, duration: 100 },
          { value: 1.1, duration: 100 },
          { value: 1, duration: 100 }
        ],
        complete: () => {
          parentNode.style.transform = 'none'
        }
      });
      
      if (this.currentWord.name !== word.name) {
        this.playClickAudio()
        this.playFailAudio()
        return false
      }

      this.showWordList[index].clicked = true

      this.playClickAudio()

      // 文字消失
      const wordTarget = document.querySelector(`#word${index} div`)
      wordTarget.style.display = 'none'
      // 变换成第一个花苞
      const flowerTarget = document.querySelector(`#word${index} .flower`)
      flowerTarget.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game5-8/anime/type0/${1}.png`
      this.boardGifHandler(`word${index}`, index)
      console.log('isVertical', this.isVertical)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-game-course-5-8{
  // font-family: 'Alibaba-PuHuiTi-Bold';
  // font-family: 'FZHuPo-M04S';
audio {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: -1;
}
.mask-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  // background: url(~https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game5-8/bg.png) center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  .overlay-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
   .mask-title {
    border: solid 3px #5f0001;
    color: #5f0001;
    font-size: 50px;
    padding: 20px 0;
    text-align: center;
    background: #fff;
    border-radius: 60px;
    width: 76%;
    margin: 5% auto;
  }
  .start {
    display: block;
    width: 124px;
  }
  .btn-restart {
    width: 223.33px;
  }
}

.home {
  height: 100%;
  width: 100%;
  background: url(https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game5-8/bg.png) center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
}
// .logo {
//   width: 12px;
//   left: 5px;
//   top: 10PX;
//   position: fixed;
// }
.sound-box-top {
  width: 46px;
  right: 20px;
  top: 10PX;
  z-index: 990;
  position: fixed;
  > img {
    z-index: 990;
    display: block;
    width: 46px;
    & + img {
      margin-top: 10px;
    }
  }
}
.sound-box-bottom {
  z-index: 990;
  width: 46px;
  right: 5px;
  bottom: 5px;
  position: fixed;
  > img {
    display: block;
    width: 46px;
    & + img {
      margin-top: 10px;
    }
  }
}
.word-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  height: 40%;
  width: 200%;
  // align-items: center;
  &:nth-of-type(1) {
    margin-left: 20px;
    margin-top: -20px;
  }
  &:nth-of-type(2) {
    margin-left: 20px;
    margin-top: 10px;
  }
  .word {
    transform-origin: center;
    width: 427.83px;
    height: 286.16px;
    font-size: 30px;
    // font-weight: bolder;
    position: relative;
    .flower {
      position: absolute; 
      width: 100%;
      // width: 319.5px;
      // height: 416.5px;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
    & + .word {
      margin-left: -200px;
    }
    .icon {
      z-index: 60;
      display: block;
      width: 27%;
      height: 52%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      left: 18%;
      transform: translateX(-50%);
      bottom: 26%;
    }
    .icon-img {
      z-index: 60;
      display: block;
      width: 74px;
      height: 74px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 30%;
    }
  }
}
#person {
  width: 213px;
  height: 250px;
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: 40;
}
#partner {
  width: 203.66px;
  height: 281.5px;
  position: fixed;
  right: 10px;
  bottom: 0px;
}
}
</style>